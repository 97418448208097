import gql from 'graphql-tag';
import { userPaymentFragment } from './fragments';

export const registerSourceIntentMutation = gql`
  mutation ($user_id: ID, $type: String) {
    registerSourceIntent(user_id: $user_id, type: $type) {
      client_secret
    }
  }
`;

export const createAppLoginTokenMutation = gql`
  mutation ($client_id: ID, $hostname: String) {
    createAppLoginToken(client_id: $client_id, hostname: $hostname) {
      id
      created_at
      user_id
      ttl
    }
  }
`;

export const registerSourceMutation = gql`
  mutation ($user_id: ID!, $token: String!, $type: String) {
    registerSource(
      user_id: $user_id
      token: $token
      stripe_account: VYTAL
      type: $type
    ) {
      ...UserPaymentFragment
    }
  }
  ${userPaymentFragment}
`;
