import { getUserHasPaymentQuery } from '../graphql/queries';
import { ApolloClient } from '@apollo/client';

export const getUserWithHasPayment = async (
  apolloClient: ApolloClient<any>,
) => {
  const getUserPaymentRes = await apolloClient.query({
    query: getUserHasPaymentQuery,
  });
  const { getUser } = getUserPaymentRes.data;
  return {
    userId: getUser.id,
    hasPayment: getUser.has_payment,
    vytalQrCode: getUser.vytal_qr_code,
  };
};
