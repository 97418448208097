import React from 'react';

import { AuthProviderProps } from './AuthProviderProps';
import { LoginOptionButton } from '../LoginOptionButton';
import { AUTH_PROVIDERS } from '../../utils/authProviders';
import AppleLogin from 'react-apple-login/dist';
import { useTranslation } from 'next-i18next';
import TRANSLATION_FILES from '../../public/locales/constants';

export function AppleLoginButton(props: AuthProviderProps) {
  const { t, ready } = useTranslation(TRANSLATION_FILES.signInUp);
  const { onSuccess } = props;

  const authCallback = (response: any) => {
    console.log('authCallback apple', response);

    const { authorization } = response;
    onSuccess(authorization.id_token, AUTH_PROVIDERS.APPLE);
  };

  if (!ready) {
    return null;
  }

  return (
    <AppleLogin
      clientId="web.vytal.org"
      redirectURI="https://web.vytal.org/welcome/login"
      usePopup
      scope="name email"
      callback={authCallback}
      render={(renderProps) => (
        <LoginOptionButton
          authProvider={AUTH_PROVIDERS.APPLE}
          label={t('loginWithApple')}
          onClick={renderProps.onClick}
        />
      )}
    />
  );
}
