import React from 'react';

import FacebookLogin from '@greatsumini/react-facebook-login';

import { AuthProviderProps } from './AuthProviderProps';
import { LoginOptionButton } from '../LoginOptionButton';
import { AUTH_PROVIDERS } from '../../utils/authProviders';
import { useTranslation } from 'next-i18next';
import { LoginResponse } from '@greatsumini/react-facebook-login/dist/types/response.type';
import TRANSLATION_FILES from '../../public/locales/constants';

export function FacebookLoginButton(props: AuthProviderProps) {
  const { t, ready } = useTranslation(TRANSLATION_FILES.signInUp);
  const { onSuccess } = props;

  const responseFacebook = (response: LoginResponse['authResponse']) => {
    console.log('responseFacebook', response);
    if (response) {
      onSuccess(response.accessToken, AUTH_PROVIDERS.FACEBOOK);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <FacebookLogin
      appId="2409518475975888"
      autoLoad={false}
      scope="public_profile"
      onSuccess={responseFacebook}
      onFail={(err) => console.error('fb login failed', err)}
      render={(renderProps: any) => (
        <LoginOptionButton
          authProvider={AUTH_PROVIDERS.FACEBOOK}
          label={t('loginWithFacebook')}
          onClick={renderProps.onClick}
        />
      )}
    />
  );
}
