import React, { useEffect, useState } from 'react';
import { FacebookLoginButton } from '../Auth/FacebookLogin';
import { AppleLoginButton } from '../Auth/AppleLogin';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { apolloUpdateJwt, hasApolloJwt } from '../../services/apolloClient';
import { getUserWithHasPayment } from '../../utils/userAuthHelper';
import { loginSuccessHandler } from '../Login/LoginSuccessHandler';
import { LoginSuccessTokenResponse } from '../../services/vytalTypes';
import { routerPushWrapper } from '../../utils/routerHelper';
import { VytalAppAuthButton } from '../Auth/VytalAppAuth';
import { AppConfig, getEnv } from '../../utils/config';
import { browserDetection } from '../../sdk/src/utils/BrowserDetection';
import { LoginOptionButton } from '../LoginOptionButton';
import { AUTH_PROVIDERS } from '../../utils/authProviders';
import { useTranslation } from 'next-i18next';
import { saveEncryptedJwtAndRefreshTokenToLocalStorage } from '../../services/localStorage';
import TRANSLATION_FILES from '../../public/locales/constants';
import { GoogleLoginButtonOAuth } from '../Auth/GoogleLoginOAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleClientId = AppConfig.GOOGLE_CLIENT_ID;

type SocialLoginButtonsProps = {
  isLoading: boolean;
  requestCookieConsent: boolean;
  cookieConsentGiven: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export default function SocialLoginButtons({
  isLoading,
  setIsLoading,
  requestCookieConsent,
  cookieConsentGiven,
}: SocialLoginButtonsProps) {
  const router = useRouter();
  const { t, ready } = useTranslation(TRANSLATION_FILES.signInUp);
  const apolloClient = useApolloClient();

  // needed to be consistent with SSR
  const [showLoginViaVytalApp, setShowLoginViaVytalApp] = useState(false);

  useEffect(() => {
    router.prefetch('/welcome/payment');

    (async () => {
      const isLoggedIn = hasApolloJwt();

      if (!isLoggedIn) {
        return;
      }

      try {
        const user = await getUserWithHasPayment(apolloClient);

        if (!user.hasPayment) {
          await routerPushWrapper('/welcome/payment');
        }
      } catch (error) {
        console.log('User is not logged in.');
      }
    })();
  }, []);

  useEffect(() => {
    if (getEnv() === 'dev' && browserDetection.isAnyMobile()) {
      setShowLoginViaVytalApp(true);
    }
  }, []);

  const onSuccessCallback = async (token: string, authProvider: string) => {
    setIsLoading(true);
    try {
      let accessJwt = null;
      // convert social login provider token or vytal app login token
      // to vytal jwt and set result via cookies
      const rawResponse = await fetch('/api/loginExternalService', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, authProvider }),
      });
      const content: LoginSuccessTokenResponse = await rawResponse.json();
      accessJwt = content.access_token;

      // update local apollo client with new jwt
      apolloUpdateJwt(accessJwt);

      // save for later use
      saveEncryptedJwtAndRefreshTokenToLocalStorage(content);

      // redirect user to payment onboarding flow if he has none
      const user = await getUserWithHasPayment(apolloClient);
      if (!user.hasPayment) {
        await routerPushWrapper('/welcome/payment');
      } else {
        await loginSuccessHandler(
          user.vytalQrCode,
          user.userId,
          content.encrypted_jwt,
          content.encrypted_refresh_token,
        );
      }
    } catch (e) {
      console.log('Error logging in', e);
    }
    setIsLoading(false);
  };

  if (!ready) return <></>;

  return (
    <>
      <div
        style={{ opacity: isLoading ? '0.5' : '1' }}
        className="flex flex-col"
      >
        {showLoginViaVytalApp && (
          <VytalAppAuthButton onSuccess={onSuccessCallback} />
        )}
        {!requestCookieConsent || cookieConsentGiven ? (
          <>
            <GoogleOAuthProvider clientId={googleClientId}>
              <GoogleLoginButtonOAuth onSuccess={onSuccessCallback} />
            </GoogleOAuthProvider>
            <FacebookLoginButton onSuccess={onSuccessCallback} />
            <AppleLoginButton onSuccess={onSuccessCallback} />
          </>
        ) : (
          <>
            {/* use the login buttons as a placeholder without functionality, so no cookies will be set
                if consent has not been given
             */}
            <LoginOptionButton
              authProvider={AUTH_PROVIDERS.GOOGLE}
              label={t('loginWithGoogle')}
            />
            <LoginOptionButton
              authProvider={AUTH_PROVIDERS.FACEBOOK}
              label={t('loginWithFacebook')}
            />
            <LoginOptionButton
              authProvider={AUTH_PROVIDERS.APPLE}
              label={t('loginWithApple')}
            />
          </>
        )}
      </div>
    </>
  );
}
