import React, { useEffect, useState } from 'react';

import ls from 'localstorage-slim';
import { AuthProviderProps } from './AuthProviderProps';
import { LoginOptionButton } from '../LoginOptionButton';
import { AUTH_PROVIDERS } from '../../utils/authProviders';
import { useApolloClient, useMutation } from '@apollo/client';
import { getAppLoginTokenQuery } from '../../graphql/queries';
import { createAppLoginTokenMutation } from '../../graphql/mutations';
import { AppConfig } from '../../utils/config';
import { LOCAL_STORAGE_KEYS } from '../../services/localStorage';
import { useRouter } from 'next/router';
import { isBrowser } from '../../utils/window';
import { getVytalAppLoginDeepLink } from '../../utils/url';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/nextjs';
import TRANSLATION_FILES from '../../public/locales/constants';

export function VytalAppAuthButton(props: AuthProviderProps) {
  const { t } = useTranslation([
    TRANSLATION_FILES.signInUp,
    TRANSLATION_FILES.errorMsg,
    TRANSLATION_FILES.common,
  ]);
  const { onSuccess } = props;

  const router = useRouter();
  const { sdk_callback_url: sdkCallbackUrl } = router.query;
  let { client_id: clientId } = router.query;

  let hostname = null;
  if (sdkCallbackUrl) {
    try {
      const url = new URL(sdkCallbackUrl as string);
      hostname = url.hostname;
    } catch (e) {
      console.log('Error getting hostname from sdkCallbackUrl', {
        sdkCallbackUrl,
        e,
      });
      Sentry.captureException(e);
    }
  } else {
    // if hostname is not set, user is logging in directly from
    // web.vytal.org. The site is not embedded or used with the vytal web sdk
    // use the current hostname and the default clientId for this
    hostname = isBrowser && window.location.hostname;
    clientId = AppConfig.VYTAL_WEB_CLIENT_ID;
  }

  const [createAppLoginToken, { loading: fetchingToken }] = useMutation(
    createAppLoginTokenMutation,
    {
      variables: {
        client_id: clientId,
        hostname,
      },
    },
  );
  const [appTokenToCheck, setAppTokenToCheck] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (!appTokenToCheck) return;

    const interval = setInterval(async () => {
      try {
        const getAppLoginTokenRes = await apolloClient.query({
          query: getAppLoginTokenQuery,
          fetchPolicy: 'network-only',
          variables: {
            app_login_token: appTokenToCheck,
          },
        });

        const { appLoginToken } = getAppLoginTokenRes.data;
        console.log({ appLoginToken });
        if (appLoginToken.user_id) {
          // Vytal App login has been confirmed in the Vytal App
          clearInterval(interval);
          setAppTokenToCheck(null);
          ls.remove(LOCAL_STORAGE_KEYS.VYTAL_APP_LOGIN_TOKEN);
          onSuccess(appLoginToken.id, AUTH_PROVIDERS.VYTAL_APP_LOGIN);
        }
      } catch (e) {
        console.log('Error checking app login token', { e });
        Sentry.captureException(e);
      }
    }, 1000);
    return () => interval && clearInterval(interval);
  }, [appTokenToCheck]);

  const onClick = async () => {
    console.log('onClick VytalAppAuthButton');

    // check if there is already a app login token
    // saved locally
    let appLoginToken: string | null | undefined = ls.get(
      LOCAL_STORAGE_KEYS.VYTAL_APP_LOGIN_TOKEN,
    );
    if (!appLoginToken) {
      let createTokenRes = null;
      try {
        createTokenRes = await createAppLoginToken();
      } catch (e) {
        console.log('Error creating app login token', { e });
        Sentry.captureException(e);
        alert(t('error-msg:unexpectedErrorLoginFeat'));
        return;
      }
      appLoginToken = createTokenRes.data.createAppLoginToken.id;
      ls.set(LOCAL_STORAGE_KEYS.VYTAL_APP_LOGIN_TOKEN, appLoginToken, {
        ttl: AppConfig.VYTAL_APP_LOGIN_TOKEN_TTL_SECONDS - 120,
      });
    }

    if (typeof appLoginToken !== 'string') {
      const errorMsg = 'Error getting app login token';
      console.warn(errorMsg);
      Sentry.captureMessage(errorMsg);
      alert(t('error-msg:loginViaAppError'));
      return;
    }

    // either this deep link will open the Vytal App
    // so redirect will happen and the user will stay on the same
    // page once he gets back
    // or the deep link will redirect to the fallback url
    // /welcome/vytal-app-login
    window.location.href = getVytalAppLoginDeepLink(appLoginToken);

    // we will only reach this, if the deep link opend the App
    setAppTokenToCheck(appLoginToken);
  };

  let loginButtonLabel = t('sign-in-up:loginViaVytalApp');
  if (fetchingToken) loginButtonLabel = `${t('common:loading')}...`;
  else if (appTokenToCheck) {
    loginButtonLabel = t('sign-in-up:waitingForVytalConfirm');
  }

  return (
    <LoginOptionButton
      authProvider={AUTH_PROVIDERS.VYTAL_APP_LOGIN}
      label={loginButtonLabel}
      onClick={onClick}
    />
  );
}
