export const AUTH_PROVIDERS = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  GOOGLEOAUTH: 'Google_OAuth',
  APPLE: 'Apple',
  VYTAL_APP_LOGIN: 'VYTAL_APP_LOGIN',
  AUTH0: 'auth0',
  EMAIL: 'email',
  ANON: 'anon',
};
