import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { checkEmailQuery, getUserQuery } from '../../graphql/queries';

import { apolloUpdateJwt } from '../../services/apolloClient';
import { LoginSuccessTokenResponse } from '../../services/vytalTypes';
import { Button } from '../Buttons/Button';
import { LinkWrapper } from '../LinkWrapper/LinkWrapper';
import { routerPushWrapper } from '../../utils/routerHelper';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/nextjs';
import { saveEncryptedJwtAndRefreshTokenToLocalStorage } from '../../services/localStorage';
import TRANSLATION_FILES from '../../public/locales/constants';
enum ContinueWithEmailErrors {
  GENERIC_ERROR,
  EMAIL_ALREADY_EXIST,
  EMAIL_DOES_NOT_EXIST,
}

export enum ContinueWithEmailMode {
  REGISTER,
  LOGIN,
}

type Props = {
  loginMode: ContinueWithEmailMode;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const ContinueWithEmailForm = ({
  loginMode,
  isLoading,
  setIsLoading,
}: Props): JSX.Element => {
  const { t, ready } = useTranslation([
    TRANSLATION_FILES.signInUp,
    TRANSLATION_FILES.errorMsg,
  ]);
  const router = useRouter();
  const { email: initialEmail } = router.query;

  const [email, setEmail] = useState<string | null>(
    (initialEmail as string) || null,
  );
  const [error, setError] = useState<ContinueWithEmailErrors | null>(null);
  const apolloClient = useApolloClient();

  const cleanedEmail = email && email.trim();

  useEffect(() => {
    router.prefetch('/welcome/payment');
    router.prefetch('/welcome/passwordless-code-verification');
  }, []);

  const requestPasswordlessCode = async () => {
    // email exists, user tries to login, request passwordless code
    try {
      const rawResponse = await fetch('/api/loginEmail', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: cleanedEmail }),
      });
      const content: LoginSuccessTokenResponse = await rawResponse.json();
      if (content.success) {
        await routerPushWrapper(
          `/welcome/passwordless-code-verification?email=${encodeURIComponent(cleanedEmail || '')}`,
        );
      } else {
        setError(ContinueWithEmailErrors.GENERIC_ERROR);
      }
    } catch (e) {
      console.log('error requesting passwordless code', e);
      Sentry.captureException(e);
      setIsLoading(false);
      setError(ContinueWithEmailErrors.GENERIC_ERROR);
    }
  };

  const createNewUserForEmail = async () => {
    try {
      const rawResponse = await fetch('/api/signupEmail', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: cleanedEmail }),
      });
      const content: any = await rawResponse.json();
      if (content.access_token) {
        // update local apollo client with new jwt
        apolloUpdateJwt(content.access_token);

        // save for later use
        saveEncryptedJwtAndRefreshTokenToLocalStorage(content);

        // call getUser for the first time, this will create the user on colugo
        const getUserRes = await apolloClient.query({ query: getUserQuery });
        const { getUser } = getUserRes.data;
        console.log('has email user', { getUser });
        if (getUser.id) {
          // new users need to set up payment
          await routerPushWrapper('/welcome/payment');
        } else {
          const debugMsg = `Error creating user for email: ${cleanedEmail}`;
          console.log(debugMsg);
          Sentry.captureMessage(debugMsg);
          setError(ContinueWithEmailErrors.GENERIC_ERROR);
          setIsLoading(false);
          return;
        }
      }
    } catch (e) {
      console.log('error creating user', e);
      Sentry.captureException(e);
      setError(ContinueWithEmailErrors.GENERIC_ERROR);
      setIsLoading(false);
      return;
    }
  };

  const onContinueClick = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // check if email already exists
      const { data: checkEmailResult } = await apolloClient.query({
        query: checkEmailQuery,
        variables: {
          email: cleanedEmail,
        },
      });
      const emailExists = checkEmailResult.checkEmail;

      if (emailExists) {
        if (loginMode === ContinueWithEmailMode.REGISTER) {
          setError(ContinueWithEmailErrors.EMAIL_ALREADY_EXIST);
          setIsLoading(false);
          return;
        }
        await requestPasswordlessCode();
      } else {
        if (loginMode === ContinueWithEmailMode.LOGIN) {
          setError(ContinueWithEmailErrors.EMAIL_DOES_NOT_EXIST);
          setIsLoading(false);
          return;
        }
        await createNewUserForEmail();
      }
    } catch (e) {
      const errMsg = `ContinueWithEmailForm error email: ${cleanedEmail}`;
      console.log(errMsg, e);
      Sentry.captureMessage(errMsg);
      Sentry.captureException(e);
      setError(ContinueWithEmailErrors.GENERIC_ERROR);
    }

    setIsLoading(false);
  };

  const handleKeypress = (keyPressed: string) => {
    //it triggers by pressing the enter key
    if (keyPressed === 'Enter') {
      onContinueClick();
    }
  };

  if (!ready) return <></>;

  // TODO: add email validation, check regex
  return (
    <>
      <input
        placeholder={t('sign-in-up:emailAddress')}
        className="vytal-input mb-2"
        data-test-id="email-input"
        value={email || ''}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(e) => handleKeypress(e.code)}
      />
      {error === ContinueWithEmailErrors.GENERIC_ERROR && (
        <span className="error-label">
          {t('error-msg:errorCreatingAccount')}
        </span>
      )}
      {error === ContinueWithEmailErrors.EMAIL_ALREADY_EXIST && (
        <span className="error-label">
          {t('sign-in-up:emailExists')} &nbsp;
          <LinkWrapper
            href={`/welcome/login?email=${encodeURIComponent(email || '')}`}
          >
            <a>{t('sign-in-up:logIn')}</a>
          </LinkWrapper>
        </span>
      )}
      {error === ContinueWithEmailErrors.EMAIL_DOES_NOT_EXIST && (
        <span className="error-label">
          {t('sign-in-up:cantFindCode')}
          &nbsp;
          <LinkWrapper
            href={`/welcome/register?email=${encodeURIComponent(email || '')}`}
          >
            <a>{t('sign-in-up:createAccount')}</a>
          </LinkWrapper>
        </span>
      )}
      <Button
        onClick={onContinueClick}
        loading={isLoading}
        disabled={!email}
        addedClassNames=" self-end mb-4 mt-2"
        data-test-id="continue-button"
      />
    </>
  );
};
