import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { getWrappedLink } from '../../utils/routerHelper';

type LinkWrapperProps = {
  href: string;
  children: any;
};

/**
 * This wraps extends the passed links to include an existing sdk_callback_url query parameter.
 * Needed for Vytal-Web-SDK use
 * @constructor
 */
export function LinkWrapper({ href, children }: LinkWrapperProps) {
  const router = useRouter();
  const adjustedHref = getWrappedLink(href, router.query);
  return <Link href={adjustedHref}>{children}</Link>;
}
