import React from 'react';
import { AuthProviderProps } from './AuthProviderProps';
import { AUTH_PROVIDERS } from '../../utils/authProviders';
import { LoginOptionButton } from '../LoginOptionButton';
import { useTranslation } from 'next-i18next';
import TRANSLATION_FILES from '../../public/locales/constants';
import { useGoogleLogin } from '@react-oauth/google';

export function GoogleLoginButtonOAuth(props: AuthProviderProps) {
  const { onSuccess } = props;
  const { t, ready } = useTranslation(TRANSLATION_FILES.signInUp);
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log('Google Login Success');
      onSuccess(tokenResponse.code, AUTH_PROVIDERS.GOOGLEOAUTH);
    },
    onError: (err) => {
      console.log('Google sign in error:', err);
    },
    flow: 'auth-code',
  });

  if (!ready) {
    return null;
  }
  return (
    <>
      <LoginOptionButton
        authProvider={AUTH_PROVIDERS.GOOGLE}
        label={t('loginWithGoogle')}
        onClick={() => login()}
      />
    </>
  );
}
