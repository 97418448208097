import React from 'react';
import styles from './LoginOptionButton.module.css';
import { AUTH_PROVIDERS } from '../utils/authProviders';

export function LoginOptionButton({
  label,
  authProvider,
  onClick,
}: {
  label: string;
  authProvider: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  let authProviderLogoClass = undefined;
  switch (authProvider) {
    case AUTH_PROVIDERS.VYTAL_APP_LOGIN:
      authProviderLogoClass = styles.vytalLogo;
      break;
    case AUTH_PROVIDERS.GOOGLE:
      authProviderLogoClass = styles.googleLogo;
      break;
    case AUTH_PROVIDERS.APPLE:
      authProviderLogoClass = styles.appleLogo;
      break;
    case AUTH_PROVIDERS.FACEBOOK:
      authProviderLogoClass = styles.facebookLogo;
      break;
    case AUTH_PROVIDERS.EMAIL:
      authProviderLogoClass = undefined;
      break;
    default:
      throw new Error(`unsupported auth provider: ${authProvider}`);
  }

  const colorBgText =
    authProvider === AUTH_PROVIDERS.VYTAL_APP_LOGIN
      ? styles.dark
      : styles.white;

  return (
    <button
      type="button"
      className={`${styles.loginOptionButton} ${colorBgText}`}
      onClick={onClick}
    >
      <div className={authProviderLogoClass} />
      {label}
    </button>
  );
}
