import Router from 'next/router';
import { sendUserIsLoggedInMessage } from '../../services/webSdkCommunicator';
import * as Sentry from '@sentry/nextjs';
import { loadEncryptedJwtAndRefreshTokenFromLocalStorage } from '../../services/localStorage';
export const loginSuccessHandler = async (
  vytalQrCodeIn: string,
  userId: string,
  encryptedJwtIn?: string,
  encryptedRefreshTokenIn?: string,
) => {
  const { sdk_callback_url: sdkCallbackUrl } = Router.query;
  if (sdkCallbackUrl) {
    let encryptedJwt = encryptedJwtIn;
    let encryptedRefreshToken = encryptedRefreshTokenIn;
    if (!encryptedJwt || !encryptedRefreshToken) {
      // in some cases, the login has been done earlier, try to get from local storage
      const encryptedJwtFromLocalStorage =
        loadEncryptedJwtAndRefreshTokenFromLocalStorage();
      if (encryptedJwtFromLocalStorage) {
        encryptedJwt = encryptedJwtFromLocalStorage.encrypted_jwt;
        encryptedRefreshToken =
          encryptedJwtFromLocalStorage.encrypted_refresh_token;
      }
    }

    // website is used within web sdk
    // send message to the parent side with result and ability to close modal
    try {
      await sendUserIsLoggedInMessage(
        vytalQrCodeIn,
        userId,
        encryptedJwt,
        encryptedRefreshToken,
      );
    } catch (e) {
      console.log('error sendUserIsLoggedInMessage', e);
      Sentry.captureException(e);
    }
    if (window.opener) {
      // the reference to the opening window is still there
      // just close this tab, confirmation will be shown in opening window
      window.close();
    } else {
      // as a fallback, navigate to the sdkCallbackUrl (should never happen)
      await Router.push(sdkCallbackUrl as string);
    }
  } else {
    await Router.push('/');
  }
};
